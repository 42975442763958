<template>
  <v-speed-dial
      v-model="fab"
      :direction="'top'"
  >
    <template v-slot:activator>
      <v-btn
          class="mb-16"
          color="pink"
          dark
          absolute
          bottom
          right
          fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </template>
    <v-btn
        fab
        dark
        small
        color="green"
    >
      <v-icon>mdi-pencil</v-icon>
    </v-btn>
    <v-btn
        fab
        dark
        small
        color="indigo"
    >
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <v-btn
        fab
        dark
        small
        color="red"
    >
      <v-icon>mdi-delete</v-icon>
    </v-btn>
  </v-speed-dial>
</template>

<script>
export default {
  name: "FastButton",
  data() {
    return {
      fab: false
    }
  }
}
</script>

<style scoped>

</style>